import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  balanceShow: false,
  isLoading: false,
  canRefreshNotifications: false,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'permission':
      return { ...state, ...rest }
    case 'isLoading':
      return { ...state, ...rest }
    case 'canRefreshNotifications':
      return { ...state, ...rest }
    case 'reset':
      return {}
    default:
      return state
  }
}

const store = createStore(changeState)

export default store
