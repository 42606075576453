import React, { createContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import UserService from '../../services/user/user.service'

// Criar o contexto
export const DataContext = createContext({})

// Criar o provedor
export function DataProvider({ children }) {
  const [favoredData, setFavoredData] = useState({})
  const [editFavoredData, setEditFavoredData] = useState({})
  const [establishmentFilters, setEstablishmentFilters] = useState({})
  const [establishmentData, setEstablishmentData] = useState({})
  const [openModalRecently, setOpenModalRecently] = useState(false)
  const [whoami, setWhoami] = useState(null)
  const [companyPayload, setCompanyPayload] = useState({})
  const [companyRatePayload, setCompanyRatePayload] = useState({})

  const fetchWhoami = useCallback(async () => {
    UserService.me().then((response) => {
      setWhoami(response.data?.data)
    })
  }, [])

  return (
    <DataContext.Provider
      value={{
        favoredData,
        setFavoredData,
        establishmentFilters,
        setEstablishmentFilters,
        establishmentData,
        setEstablishmentData,
        editFavoredData,
        setEditFavoredData,
        openModalRecently,
        setOpenModalRecently,
        whoami,
        setWhoami,
        fetchWhoami,
        companyPayload,
        setCompanyPayload,
        setCompanyRatePayload,
        companyRatePayload,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

DataProvider.propTypes = {
  children: PropTypes.node,
}
