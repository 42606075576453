import 'react-calendar/dist/Calendar.css'
import 'react-date-picker/dist/DatePicker.css'
import 'react-notifications/lib/notifications.css'
import './scss/style.scss'
import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { NotificationContainer } from 'react-notifications'
import { DataProvider } from './helpers/hooks/useContext'
import TwoFactorAuthenticationDataProvider from './views/pages/settings/profile/two-factor-authentication/hooks/useTwoFactorAuthenticationContext'
import CompanyService from './services/company/company.service'
import EstablishmentDataProvider from './views/pages/establishment/components/hooks/useEstablishmentContext'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/login'))
const TwoFactorChallenge = React.lazy(() => import('./views/pages/login/2fa-challenge'))
const RecoverPassword = React.lazy(() => import('./views/pages/recover_password/recoverPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/recover_password/resetPassword'))
const Register = React.lazy(() => import('./views/pages/register/register'))
const Page404 = React.lazy(() => import('./views/pages/page404/page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/page500'))
const Page451 = React.lazy(() => import('./views/pages/page451/page451'))
const queryClient = new QueryClient()

class App extends Component {
  loading = false

  componentDidMount() {
    this.loadTitle().then()
    this.loadFavIcon().then()
    this.hideSacWidget()
  }

  async loadTitle() {
    this.loading = true
    try {
      const domain = window.location.hostname
      const baseURL = process.env.REACT_APP_URL
      const response = await fetch(`${baseURL}/company/${domain}/name`)
      if (!response.ok) {
        document.title = 'Painel'
        return
      }

      const data = await response.json()
      document.title = `${data.fantasy_name} - Painel`
      const metaDescription = document.getElementById('meta-description')
      if (metaDescription) metaDescription.content = `Sistema Administrativo ${data.fantasy_name}`
      this.loading = false
    } catch (error) {
      this.loading = false
      console.error('Error updating title:', error)
    }
  }

  async loadFavIcon() {
    try {
      const domain = window.location.hostname
      const response = await CompanyService.getCompanyLogo(domain)
      const favicon = document.getElementById('favicon')
      if (favicon) favicon.href = response?.data?.logo
    } catch (error) {}
  }

  hideSacWidget() {
    const element = document.getElementsByClassName('close-widgets-container')[0]
    if (element) {
      element.remove()
    }
  }

  render() {
    return (
      <>
        <QueryClientProvider client={queryClient}>
          <DataProvider>
            <EstablishmentDataProvider>
              <TwoFactorAuthenticationDataProvider>
                <NotificationContainer />
                <HashRouter>
                  <Suspense fallback={loading}>
                    <Routes>
                      <Route exact path="/login" name="Login Page" element={<Login />} />
                      <Route
                        exact
                        path="/2fa-challenge"
                        name="2FA Page"
                        element={<TwoFactorChallenge />}
                      />
                      <Route
                        exact
                        path="/recover_password"
                        name="Login Page"
                        element={<RecoverPassword />}
                      />
                      <Route
                        exact
                        path="/reset_password/:email"
                        name="Reset Password Page"
                        element={<ResetPassword />}
                      />
                      <Route path="refresh" element={<></>} />
                      <Route exact path="/register" name="Register Page" element={<Register />} />
                      <Route exact path="/404" name="Page 404" element={<Page404 />} />
                      <Route exact path="/500" name="Page 500" element={<Page500 />} />
                      <Route exact path="/451" name="Page 451" element={<Page451 />} />
                      <Route path="*" name="Home" element={<DefaultLayout />} />
                    </Routes>
                  </Suspense>
                </HashRouter>
              </TwoFactorAuthenticationDataProvider>
            </EstablishmentDataProvider>
          </DataProvider>
        </QueryClientProvider>
      </>
    )
  }
}

export default App
