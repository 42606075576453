import { createContext, useState } from 'react'
import React from 'react'
import PropTypes from 'prop-types'

export const TwoFactorAuthenticationDataContext = createContext({})

function TwoFactorAuthenticationDataProvider({ children }) {
  const [two_factor_confirmed, setTwoFactorConfirmed] = useState(null)
  const [two_factor_recovery_codes_has_expired, setTwoFactorRecoveryCodesHasExpired] =
    useState(false)
  const [two_factor_recovery_codes_remaining_time, setTwoFactorRecoveryCodesRemainingTime] =
    useState(0)

  return (
    <TwoFactorAuthenticationDataContext.Provider
      value={{
        two_factor_confirmed,
        setTwoFactorConfirmed,

        two_factor_recovery_codes_has_expired,
        setTwoFactorRecoveryCodesHasExpired,

        two_factor_recovery_codes_remaining_time,
        setTwoFactorRecoveryCodesRemainingTime,
      }}
    >
      {children}
    </TwoFactorAuthenticationDataContext.Provider>
  )
}

TwoFactorAuthenticationDataProvider.propTypes = {
  children: PropTypes.node,
}

export default TwoFactorAuthenticationDataProvider
