import { createContext, useState } from 'react'
import React from 'react'
import PropTypes from 'prop-types'

export const EstablishmentDataContext = createContext({})

function EstablishmentDataProvider({ children }) {
  const [newTerminalIsDisabled, setNewTerminalIsDisabled] = useState(true)
  const [terminalData, setTerminalData] = useState([])

  return (
    <EstablishmentDataContext.Provider
      value={{
        newTerminalIsDisabled,
        setNewTerminalIsDisabled,
        terminalData,
        setTerminalData,
      }}
    >
      {children}
    </EstablishmentDataContext.Provider>
  )
}

EstablishmentDataProvider.propTypes = {
  children: PropTypes.node,
}

export default EstablishmentDataProvider
