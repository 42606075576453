import HttpService from '../http/httpService'

class CompanyService extends HttpService {
  resource = '/company'
  primaryKey = 'id_company'

  profitSearch(filters, pagination = { per_page: 10, page: 1, sort: null }) {
    try {
      if (Object.keys(pagination)) {
        pagination = this._serialize(pagination)
      }

      return this.instance.get(
        `${this.resource}/profit/search?paginate=true&${this._serializeFilter(
          filters,
        )}&${pagination}`,
      )
    } catch {
      console.error('caiu')
    }
  }

  async all() {
    return this.instance.get(`${this.resource}/all`)
  }

  async load() {
    let response = await this.get()
    return response.data.data.map((companyObj) => {
      return {
        ...companyObj,
        label: companyObj.name,
        value: companyObj.id_company,
      }
    })
  }

  async loadSpecificCompany(id) {
    return this.instance.get(`${this.resource}/${id}`)
  }

  async updateCompany(id_company, data) {
    return this.instance.put(`${this.resource}/${id_company}`, data)
  }

  async saveCompany(data) {
    return this.instance.post(`${this.resource}`, data)
  }

  async unarchive(id_company) {
    return this.instance.patch(`${this.resource}/${id_company}/unarchive`)
  }

  async setUserRepresentative(id_company, data) {
    return this.instance.patch(`${this.resource}/${id_company}/set-representative-commission`, data)
  }

  async removeUserRepresentative(id_company) {
    return this.instance.patch(`${this.resource}/${id_company}/reset-representative`)
  }

  async getCompanyLogo(domain) {
    return this.instance.get(`${this.resource}/${domain}/logo`)
  }
}

export default new CompanyService()
