/* eslint-disable no-useless-concat */
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import parseFilterWithRelations from '../../helpers/requests/parseFilterWithRelations'

class HttpService {
  resource = ''
  primaryKey = 'id'

  const
  instance

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      timeout: 0,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'client-id': 'fd99127c-bfc3-458b-b419-2d4d9374bc5b',
        'client-secret': '2bb4e87f-1810-4b80-b8d5-f8ae2265afd3',
      },
    })

    this.instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token')
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.code === 'ECONNABORTED') {
          NotificationManager.error('Ocorreu um erro, tente novamente!', 'Oops', 5000, () => {})
          return error
        }

        const statusError = error.response?.status

        if (statusError === 401 || error.response?.data?.message === 'Unauthenticated.') {
          localStorage.removeItem('token')
          NotificationManager.remove()

          if (window.location.pathname !== '/' && window.location.pathname !== '/login') {
            NotificationManager.error('Sua sessão expirou!', 'Oops', 5000, () => {})
            window.location.reload()
          }
        }

        if (statusError === 421) {
          NotificationManager.error(
            'Você não possui permissão para acessar esta funcionalidade.',
            'Oops',
            5000,
            () => {},
          )
        }

        if (statusError === 451) {
          window.history.replaceState(null, '', '/#/establishment/transactions')
          window.location.href = '/#/451'
        }

        if (statusError === 429) {
          NotificationManager.error(
            'Muitas tentativas. Tente novamente em alguns minutos!',
            'Oops',
            5000,
            () => {},
          )
        }
        throw error
      },
    )
  }

  find(id) {
    return this.instance.get(`${this.resource}/${id}`)
  }

  get() {
    return this.instance.get(`${this.resource}`)
  }

  getTable(
    filters,
    pagination = { per_page: 10, page: 1, sort: null },
    withRelations = {
      withRelations: null,
    },
  ) {
    if (Object.keys(pagination)) {
      pagination = this._serialize(pagination)
    }

    return this.instance
      .get(
        `${this.resource}/search?paginate=true&${this._serializeFilter(
          filters,
        )}&${pagination}${this._serializeFilterWithRelations(withRelations?.withRelations)}`,
      )
      .catch(function (error) {
        NotificationManager.error(error.response.data.message, '', 5000, () => {})
        return error.response
      })
  }

  post(data) {
    return this.instance.post(`${this.resource}`, data)
  }

  put(data) {
    return this.instance.put(`${this.resource}/${this.getPrimary(data)}`, data)
  }

  delete(id) {
    return this.instance.delete(`${this.resource}/${id}`)
  }

  archive(id) {
    return this.instance.delete(`${this.resource}/${id}`)
  }

  getPrimary(data) {
    return data[this.primaryKey]
  }

  search(
    filters,
    withRelations = {
      withRelations: null,
    },
  ) {
    return this.instance.get(
      `${this.resource}/search?${this._serializeFilter(
        filters,
      )}${this._serializeFilterWithRelations(withRelations?.withRelations)}`,
    )
  }

  _serializeFilterWithRelations(stringRelations) {
    const relations = parseFilterWithRelations(stringRelations)
    if (relations) {
      return `&withRelations=${relations}`
    }
    return ''
  }

  _serializeFilter(obj, prefix = 'filter') {
    let str = []
    for (let p in obj) {
      if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
        let key = prefix ? `${prefix}[${encodeURIComponent(p)}]` : encodeURIComponent(p)
        if (typeof obj[p] === 'object' && !Array.isArray(obj[p])) {
          str.push(this._serializeFilter(obj[p], key))
        } else {
          str.push(`${key}=${encodeURIComponent(obj[p])}`)
        }
      }
    }

    return str.join('&')
  }

  _serialize(obj) {
    let str = []
    for (let p in obj)
      if (obj.hasOwnProperty(p) && obj[p] !== undefined && obj[p] !== null) {
        str.push(`${encodeURIComponent(p)}` + '=' + encodeURIComponent(obj[p]))
      }
    return str.join('&')
  }

  _serializeArray(arr) {
    let str = []
    arr.forEach((item) => {
      str.push(`columns[${encodeURIComponent(item.key)}]` + '=' + encodeURIComponent(item.name))
    })
    return str.join('&')
  }
}

export default HttpService
