/**
 *
 * @param {Array<string>} value
 * @returns {string}
 */
export default function parseFilterWithRelations(value) {
  if (!Array.isArray(value)) {
    return ''
  }

  if (value.length === 0) {
    return ''
  }
  return value.join(',')
}
